<template>
  <div>
    <div class="row my-3">
      <h1 class="col-sm-12">Configuración</h1>
    </div>

    <DataGrid :configuracion="datagrid_config" :data="all_conf" @ready="buscar_btns">
      <button class="btn btn-secondary mr-2" @click="obtener_configuracion">Actualizar</button> 
      <button class="btn btn-primary" @click="show_modal=true">Crear</button>
    </DataGrid>

    <Modal v-if="show_modal" :config="conf" @close="cerrar_modal" @update="cerrar_modal(true)" />
  </div>
</template>

<script>
import DataGrid from '@/components/DataGridV3';
import Modal from './Configuracion/Modal.vue';

import api from '@/apps/analyticpoint/api/comite/configuracion';

export default {
  components: {
    DataGrid, Modal
  },
  data() {
    return {
      datagrid_config: {
        cols: {
          nombre: 'Nombre',
          valor: 'Valor',
          tipo: 'Tipo',
          descripcion: 'Descripción',
          otros: 'Otros'
        },
        mutators:{
          otros(val, row, vue) {
            return '<button class="btn btn-secondary btn-editar" data-config="'+vue.$helper.toJson(row)+'"><i class="fa-regular fa-pen-to-square"></i></button>';
          }
        },
        selector: false,
      },
      all_conf:[],
      conf:null,
      show_modal: false
    }
  },
  mounted() {
    this.obtener_configuracion();
  },
  methods: {
    async obtener_configuracion() {
      try {
        this.all_conf = (await api.obtener_configuraciones()).data;
      }catch(e) {
        this.$log.error('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    }
    ,buscar_btns() {
      console.log('buscamos btns');
      document.querySelectorAll('button.btn-editar').forEach(btn => {
        btn.removeEventListener('click',this.abrir_modal);
        btn.addEventListener('click',this.abrir_modal);
      })
    },
    abrir_modal(e) {
      let btn = e.target;
      
      if (btn.tagName == 'I')
        btn = btn.parentElement;

      this.conf = this.$helper.fromJson(btn.dataset.config);
      this.show_modal = true;
    },
    cerrar_modal(recargar) {
      if (typeof recargar == 'undefined')
        recargar = false;

      this.show_modal = false;
      this.conf = null;

      if (recargar)
        this.obtener_configuracion();
    }
  }
}
</script>